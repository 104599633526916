var ShowPrompt = CINC.Core.ShowPrompt = function (force) {
    force = (typeof force === 'undefined' ? false : force);

    if (CINC.User && CINC.User.securityLevel && CINC.User.securityLevel > 0) {
        return;
    }

    if (CINC.QS && CINC.QS['showprompt'] === '1') {
        force = true;
    }

    if (CINC.Core.Cookies.Get('fbprompt') === '1' || (CINC.QS && CINC.QS['fbprompt'] === '1')) {
        force = true;
        CINC.Core.CookiesVanilla.Set('fbprompt', '1', 1);

        // remove fbprompt=1 from the querystring so customers don't get any ideas
        try
        {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            if (window.location.search)
            {
                var newsearch = "?" + $.grep(window.location.search.substring(1).split('&'), function (v) {
                    return v != 'fbprompt=1';
                }).join('&');
                if (newsearch != '?')
                    newurl += newsearch;
            }

            History.pushState({ path: newurl }, '', newurl);
        }
        catch (err)
        {

        }
    }

    if (CINC.QS && CINC.QS['mobilehideviews']) {
        var maxViews = parseInt(CINC.QS['mobilehideviews']);
        if (maxViews > 0) {
            CINC.Site.PromptViews = maxViews;
            CINC.Core.CookiesVanilla.Set('maxviews', maxViews, 1);
        }
    }
    else if (CINC.Core.CookiesVanilla.Get('maxviews') !== '') {
        var maxViews = parseInt(CINC.Core.CookiesVanilla.Get('maxviews'));
        if (maxViews > 0) {
            CINC.Site.PromptViews = maxViews;
        }
    }

    var controller = (CINC.ControllerName || '').toLowerCase();
    var views = (parseInt(CINC.Core.CookiesVanilla.Get('promptviews')) || 0);
    if (!force) {
        if (CINC.Site.PromptLevel === 0 || controller === 'sellersuite') {
            return;
        }
        switch (CINC.Site.PromptPages) {
            case 0:
                if (controller === 'account') {
                    return;
                }
                break;
            case 1:
                if (controller !== 'propertydetails') {
                    return;
                }
                break;
            case 2:
                if (controller !== 'search') {
                    return;
                }
                break;
            case 3:
                if (controller !== 'propertydetails' && controller !== 'search') {
                    return;
                }
                break;
        }
        CINC.Core.CookiesVanilla.Set('promptviews', ++views, 7);
        if (views <= CINC.Site.PromptViews) {
            return;
        }
    }
    
    var allowDismiss = !(force || CINC.Site.PromptLevel > 1);
    CINC.Core.PromptForSignup('', allowDismiss);
};

var PromptForSignup = CINC.Core.PromptForSignup = function (message, allowDismiss, step, thankYouText) {
    allowDismiss = (typeof allowDismiss === 'undefined' ? true : allowDismiss);
    step = (typeof step === 'undefined' ? '1' : String(step));

    if (thankYouText !== undefined && thankYouText !== null && thankYouText.length !== 0)
        $('.js-thankyou-content').html(thankYouText);

    var result = PromptForLogin(message);

    CINC.Core.WriteConversionScripts();

    $('.js-signup-step').each(function () {
        var $step = $(this);
        $step.toggle($step.attr('data-signup-step') === step);
    });

    var subdomain = CINC.GetSubdomain();

    if (CINC.CincProduct == "Houses" && subdomain != null && subdomain.length == 0) {
        $('.js-signupform').hide();
        $('.js-signinform').hide();
        window.location.replace('/');
        return false;
    }

    $('.js-signupmessage').html(message || 'Join us and see how our powerful notebook features simplify your home search process, saving you time and hassle!');
    $('.js-signupform').show();
    $('.js-signinform').hide();

    var socialButtons = $('.js-socialsignin-buttons');
    if (socialButtons.hasClass('hidden')) {
        socialButtons.removeClass('hidden');
        socialButtons.find('iframe[data-src]').each(function () {
            $(this).attr('src', $(this).attr('data-src'));
            $(this).attr('data-src', null);
        });
    }

    $('.js-signup-close').toggle(allowDismiss);
    result.Element.next('.modal-window').toggleClass('non-dismissable', !allowDismiss);

    return result;
};

$(document).ready(function() {
    CINC.Core.ShowPrompt(false);
});
