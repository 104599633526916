(function (ga) {
    $(document).ready(function () {
        signupToContinue();

        $('.js-apply-now-loan').on('click', function (e) {
        });

        $('.js-facebooksignin').on('click', function (e) {
            e.preventDefault();

            if (IsLoggedIn) return;

            CINC.Registration.closeSignInModals('Log In With Facebook');

            FB.login(function (response) {
                if (response.status !== 'connected') return;

                // only bother with the loading modal if we're waiting for a seconds
                var loadingModal = Modals.Loading("Logging In");
                var loadingModalTimeout = setTimeout(function () {
                    clearTimeout(loadingModalTimeout);
                    loadingModal.Open();
                }, 1000);

                CINC.Api.Account('facebooksignin')
                    .post(response.authResponse)
                    .success(function (r) {
                        if (r.isSuccess) {
                            if (r.isNewUser)
                                SetLoadAction("NotificationBanner", { message: "Welcome to " + CINC.Site.DomainName + ", " + CINC.User.firstName + "!", type: "Info" });

                            window.location.reload();
                        }
                        else {
                            var description = r.description || "";
                            Notification.Banner.Error(description, "Login failed");
                        }
                    })
                    .fail(function (f) {
                        Notification.Banner.Error(f.responseText, "Failed to log in");
                    })
                    .always(function () {
                        loadingModal.Close();
                    });
            }, { scope: 'public_profile,email' });
        });

        function continueFacebookSignup(facebookProfile) {
            CINC.Registration.closeSignInModals('Log In With Facebook');
            CINC.Integrations.Facebook.Auth = facebookProfile.authResponse;
            CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);

            if (CINC.Core.CookiesVanilla.Get('fbprompt') === '1' || (CINC.QS && CINC.QS['fbprompt'] === '1')) {
                var phoneInputElement = $('.js-signupform-required-phone').find('[name="Phone"]');
                phoneInputElement.val('123456789012345');
                $('.js-signupform-required-phone').trigger('submit');
            }
            else {
                if (CINC.Site.Registration_SocialWithoutPhone) {
                    var signupContext = CINC.Api.Account('facebooksignin');
                    var signupPayload = CINC.Integrations.Facebook.Auth;
                    var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
                    signupContext.post(signupPayload).success(function(result) {
                        if (!result.isSuccess) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Facebook');
                            }
                            CINC.Registration.callbacks.registrationError(result, loadingModal);
                        } else {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Succeeded with Facebook');
                            }
                            PromptForSignup('', false, '1-phone');
                        }
                        })
                        .fail(function (f) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Facebook');
                            }
                            CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
                        });;
                } else {
                    PromptForSignup('', false, '1-phone');
                }
            }
        }

        function continueGoogleSignup(googleProfile) {
            CINC.Registration.closeSignInModals('Create Account');
            CINC.Integrations.Google.User = googleProfile;
            CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);

            if (googleProfile.phoneNumber == null || googleProfile.phoneNumber === '') {
                if (CINC.Site.Registration_SocialWithoutPhone) {
                    var signupContext = CINC.Api.AccountSocialSign('google');
                    var loading = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
                    signupContext.post(googleProfile).success(function(result) {
                        if (!result.isSuccess) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Google');
                            }
                            CINC.Registration.callbacks.registrationError(result, loading);
                        } else {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Successful with Google');
                            }
                            PromptForSignup('', false, '1-phone');
                        }
                        })
                        .fail(function (f) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Google');
                            }
                            CINC.Registration.callbacks.registrationApiFailure(f, loading);
                        });;
                } else {
                    PromptForSignup('', false, '1-phone');
                }
            } else {
                var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
                CINC.Api.AccountSocialSign('google').post(googleProfile)
                    .success(function (result) {
                        if (result.isSuccess) {
                            CINC.Registration.callbacks.registrationSuccess(result);
                        } else {
                            CINC.Registration.callbacks.registrationError(result, loadingModal);
                        }
                    })
                    .fail(function (f) {
                        CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
                    });
            }
        }

        function signupToContinue() {
            if ((CINC.QS && CINC.QS['signup'] === '1') && (typeof CINC.User === 'undefined' || CINC.User == null)) {
                PromptForSignup('', false);
            }
        }

        window.onmessage = function (e) {
            if (e.data === 'google-signin-fail') {
                Notification.Banner.Error(
                    'Sorry! We are unable to retrieve your Google profile information. Please register by entering your information in the form.',
                    'Unable to Get Google Profile');
            } else if (e.data === 'facebook-signin-fail') {
                Notification.Banner.Error(
                    'Sorry! We are unable to retrieve your Facebook profile information. Please register by entering your information in the form.',
                    'Unable to Get Facebook Profile');
            } else if (e.data && e.data.authResponse && e.data.status) {
                continueFacebookSignup(e.data);
            } else if (e.data && e.data.familyName && e.data.givenName && e.data.email) {
                continueGoogleSignup(e.data);
            }
        };
    })
})(typeof ga === 'undefined' || ga === null ? function () { } : ga);