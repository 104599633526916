$('.toggleSignup').on('click', function (e) {
    e.preventDefault();
    if ($('.js-signinform').is(':visible')) {
        $('.js-signupform').show();
        $('.js-signinform').hide();
        if (typeof ga !== 'undefined') {
        }
        var socialButtons = $('.js-socialsignin-buttons');
        if (socialButtons.hasClass('hidden')) {
            socialButtons.removeClass('hidden');
            socialButtons.find('iframe[data-src]').each(function () {
                $(this).attr('src', $(this).attr('data-src'));
                $(this).attr('data-src', null);
            });
        }
    }
    else {
        $('.js-signinform').show();
        $('.js-signupform').hide();
    }
});

$(".js-promptforlogin").on('click', function (e) {
    e.preventDefault();
    PromptForLogin();
});

$(".js-logout").on('click', function (e) {
    e.preventDefault();
    CINC.Api.Account('logout')
        .post()
        .success(function (result) {
            window.location = '/';
        })
        .fail(function (f) {
        });
});

$(".js-promptforsignup").on('click', function (e) {
    e.preventDefault();
    PromptForSignup();
});