/*  
This is now Deprecated 9/28/2023 https://cincdev.atlassian.net/browse/CON-4936 will remove it
*/
$('.js-signupform-form').on('submit', function (e) {
    e.preventDefault();

    var $form = $(this);
    var data = $form.serializeObject();
    if (_.isObject(CINC.Property)) {
        data.price = CINC.Property.price;
        data.city = CINC.Property.city;
        data.state = CINC.Property.state;
        data.zip = CINC.Property.zip;
    }
    var searchTermsAgree = $form.find('.js-searchtermsagree');
    if (searchTermsAgree.length === 1 && !searchTermsAgree.prop('checked')) {
        Notification.Tooltip.Show("Please accept the terms and conditions", $form.find('.js-searchtermsagree'));
        return;
    }

    data.FirstName = $.trim(data.FirstName || '');
    if (!data.FirstName || data.FirstName.length === 0) {
        Notification.Tooltip.Show("Please enter your name", $form.find('[name="FirstName"]'));
        return;
    }

    var firstLast = data.FirstName.split(" ");
    if (firstLast.length < 1 || (firstLast.length === 1 && !CINC.Site.Registration_AllowPartialName)) {
        Notification.Tooltip.Show("Please enter your full name", $form.find('[name="FirstName"]'));
        return;
    }

    for (var i = 0; i < firstLast.length; i++) {
        if (firstLast[i].length === 0) continue;

        firstLast[i] = firstLast[i].replace(/[^a-zA-Z]+/g, '');
        if (firstLast[i].length === 0) {
            Notification.Tooltip.Show("Please enter a valid name", $form.find('[name="FirstName"]'));
            return;
        }
    }

    data.Email = $.trim(data.Email || '');
    if (!data.Email || !IsEmail(data.Email)) {
        Notification.Tooltip.Show("Please enter a valid email", $form.find('[name="Email"]'));
        return;
    }

    var phoneInputElement = $form.find('[name="Phone"]');
    if (!CINC.Registration.isValid.phoneInputValue(phoneInputElement, data.Phone)) {
        return;
    }

    var $password = $form.find('[name="Password"]');
    if ($password.length === 1) {
        if (!data.Password || data.Password.length === 0) {
            Notification.Tooltip.Show("Please enter a password", $password);
            return;
        }
    }
    else if (phoneInputElement.length === 1) {
        data.Password = data.Phone;
    }

    data.Timezone = CINC.currentTimezone();
    data.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    CINC.Registration.closeSignInModals('Create Account');
    var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();

    CINC.Core.Cookies.Set('registration-state', 'unregistered', 5);
    CINC.Api.Account('register')
        .post(data)
        .success(function (result) {
            if (result.isSuccess) {
                if (window.report_conversion) {
                    window.report_conversion();
                }
                CINC.Registration.callbacks.registrationSuccess(result);
            } else {
                CINC.Registration.callbacks.registrationError(result, loadingModal);
            }
        })
        .fail(function (f) {
            CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
        })
        .always(function() {
        });

    return false;
});

/* Signup Form Step 1.5 (Phone only -- potential 3rd party sign in flow) */
$('.js-signupform-required-phone').on('submit', function (e) {
    e.preventDefault();

    var $form = $(this);
    var data = $form.serializeObject();
    var phoneInputElement = $form.find('[name="Phone"]');

    if (!CINC.Registration.isValid.phoneInputValue(phoneInputElement, data.Phone)) {
        return;
    }

    // Set up of 3rd party (Facebook or Google) vars
    var signupContext = CINC.Integrations.Facebook.Auth ? CINC.Api.Account('facebooksignin') : CINC.Api.AccountSocialSign('google');
    var signupPayload = CINC.Integrations.Facebook.Auth || CINC.Integrations.Google.User;
    signupPayload.phoneNumber = data.Phone; // Add phone number obtained to payload

    CINC.Registration.closeSignInModals('Phone Number Registration');
    var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
    signupContext.post(signupPayload)
        .success(function (result) {
            if (result.isSuccess) {
                CINC.Registration.callbacks.registrationSuccess(result);
            } else {
                CINC.Registration.callbacks.registrationError(result, loadingModal);
            }
        })
        .fail(function (f) {
            CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
        });
});