/* Signup Form Step 3a (Seller) */
$('.js-signupform-page-3').on('submit', function (e) {
    e.preventDefault();

    var $form = $(this);
    var data = $form.serializeObject();

    var questions = {};

    $('.js-signupform-page-3 :input').each(function () {
        if (!!$(this).attr('name'))
            questions[$(this).attr('name')] = true;
    });

    if (Object.keys(questions).length != Object.keys(data).length) {
        Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search');
        return;
    }

    data.SurveyAnswers = Object.keys(data).map(function (itm) { return data[itm]; });

    CINC.Registration.closeSignInModals('Create Account');
    var loadingModal = Modals.Loading('').Open();
    CINC.Api.Account('register/extra')
        .post(data)
        .success(function (result) {
            PromptForSignup('', false, '3b');
        })
        .fail(function (f) {
            Notification.Banner.Error(f.responseText, "Submission Error");
            PromptForSignup('Submission Failed', false, '2');
        });

    return false;
});